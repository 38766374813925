cds-radio {
  --cds-focus: #B69541;
  --cds-icon-primary: white;

  .cds--radio-button__appearance {
    border-color: var(--neom-input-radio-border-color);
  }
  .cds--radio-button:checked + .cds--radio-button__label {
    .cds--radio-button__appearance {
      border-color: var(--neom-input-radio-border-color);
      background-color: var(--neom-input-radio-background-color);
    }

    .cds--radio-button__appearance::before {
      background-color: white;
    }
  }

  .cds--radio-button:focus + .cds--radio-button__label {
      .cds--radio-button__appearance {
        outline: 2px solid var(--neom-input-radio-border-color) ;
      }
  }

  * {
    font-weight: 700;
    font-size: 15px;
  }
}
