.neom-button-rounded {
  align-items: center;
  gap: 4px;
  background-color: var(--neom-button-color);
  border-radius: 12px;
  height: 12px;
  color: var(--neom-button-text-color);
  border-color: transparent;
  font-weight: 600;

  &:hover {
    background-color: var(--neom-button-hover-color);
    cursor: pointer;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--neom-button-hover-color);
    background-color: var(--neom-button-color);
    border-color: transparent;
  }
}

.neom-button {
  background-color: var(--neom-button-color);
  color: var(--neom-button-text-color);
  align-items: center;
  font-weight: 600;

  span {
    padding-top : 4px;
  }

  &:hover {
    background-color: var(--neom-button-hover-color);
    cursor: pointer;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--neom-button-hover-color);
    border-color: transparent;
  }

  i {
    line-height: 0px;
  }
}

.neom-save-button {
  @extend .neom-button;
  background: rgba(295, 192, 63, 0.5);
}

.cds--btn {
  span {
    padding-top : 4px;
  }
  
  padding-inline-start: 0;
  padding-inline-end: 0;
  display: flex;
  justify-content: center;
  padding: 8px;
}
