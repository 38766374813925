/* You can add global styles to this file, and also import other style files */
@use '@carbon/styles' with (
  $use-flexbox-grid: true,
  $font-path: '@ibm/plex'
);

@use '@carbon/styles/scss/grid/flexbox';

@import '@carbon/charts/styles/styles.scss';
@import './icons.scss';

/* Put shared styles defined by the team here. */
@import './app/shared/styles/button.scss';
@import './app/shared/styles/checkbox.scss';
@import './app/shared/styles/positioning.scss';
@import './app/shared/styles/card.scss';
@import './app/shared/styles/modal-with-background.scss';
@import './app/shared/styles/pie-chart.scss';
@import './app/shared/styles/radio.scss';
@import './app/shared/styles/form.scss';
@import './app/shared/styles/gauge-chart.scss';
@import './app/shared/styles/input.scss';
@import './app/shared/styles/layout.scss';

/* Carbon styles that need to be overriden */
@import './app/shared/styles/carbon-override.scss';

/* Fonts */
@import './fonts.scss';

html {
  background-color: var(--neom-main-container-background);
}

:root {
  --neom--sidenav--background: #1d1f22;
  --neom-main-container-background: #f7f5ec;
  --neom-border-color: #e4e5e7;
  --neom-button-color: #ebc03f;
  --gauge-chart-item-color: #ebc03f;
  --neom-button-hover-color: #a98311;
  --neom-button-text-color: #000000;
  --neom-button-hover-color: #a07d13;
  --neom-checkbox-color: #b69541;
  --neom-checkbox-icon-color: #ffffff;
  --neom-card-color: #ffffff;
  --neom-button-border-color: #000000;
  --neom-input-background: #ffffff;
  --neom-text-color: #1d1f22;
  --neom-user-icon-border-color: #0099ba;
  --neom-user-icon-background-color: #f7f5ec;
  --neom-text-legend-color: #d0d1d2;
  --neom-input-radio-border-color: #b69541;
  --neom-input-radio-background-color: #b69541;
  --neom-validation-input-text-color: red;
  --neom-focus-color: #ebc03f;
  --neom-input-label-color: #525252;
  --neom-page-title-color: #13100dBF;
}
