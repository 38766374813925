// disable percentages in pie chart
.pie-label {
  display: none;
}

.legend-item {
  p {
    color: var(--neom-text-legend-color);
    font-weight: bold;
  }
}

.cds--cc--chart-wrapper text {
  font-weight: bold;
}
