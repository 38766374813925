@use '@carbon/styles/scss/theme';
@use '@carbon/styles/scss/themes';
@use 'globals';
@use 'tokens';
@include theme.add-component-tokens(tokens.$custom-charting-tokens);

.#{globals.$prefix}--chart-holder {
	position: relative;
	display: block;
	width: 100%;
	height: 100%;

	&.filled,
	&.fullscreen {
		background-color: theme.$background;

		.#{globals.$prefix}--#{globals.$charts-prefix}--chart-wrapper {
			background-color: theme.$background;
		}
	}

	// May not be needed any longer
	.DONT_STYLE_ME_css_styles_verifier {
		overflow: hidden;
		opacity: 0;
	}
}

.#{globals.$prefix}--chart-holder {
	@include theme.theme(themes.$white);
}

.#{globals.$prefix}--chart-holder[data-carbon-theme='g10'] {
	@include theme.theme(themes.$g10);
}

.#{globals.$prefix}--chart-holder[data-carbon-theme='g90'] {
	@include theme.theme(themes.$g90);
}

.#{globals.$prefix}--chart-holder[data-carbon-theme='g100'] {
	@include theme.theme(themes.$g100);
}

.#{globals.$prefix}--chart-holder.fullscreen,
.#{globals.$prefix}--chart-holder:-webkit-full-screen {
	// important used to ensure full-screen experience
	width: 100% !important;
	height: 100% !important;
	max-width: unset !important;
	max-height: unset !important;
	padding: 2em;
}
