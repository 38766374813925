.neom-icon-30 {
  width: 30px;
  height: 30px;
}

.neom-icon-40 {
  width: 40px;
  height: 40px;
}

.neom-icon-50 {
  width: 50px;
  height: 50px;
}

.neom-icon-60 {
  width: 60px;
  height: 60px;
}
