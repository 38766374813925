.neom-position-bottom-right {
    position: fixed;
    bottom: 30px;
    right: 10px;
}

.neom-tab-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}