@font-face {
  font-family: BrownPro;
  src: url('./assets/fonts/BrownPro/BrownPro-Light.otf') format('opentype');
}

@font-face {
  font-family: BrownPro-Regular;
  src: url('./assets/fonts/BrownPro/BrownPro-Regular.otf') format('opentype');
}

body {
  font-family: BrownPro-Regular;
}
