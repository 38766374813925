.text-danger {
  text-align: start;
  color: var(--neom-validation-input-text-color);
  font-size: 12px;
}

.input-container {
  // height: 78px;
}

.cds--label {
  margin-block-end: 0;
  font-weight: bold;
  color: var(--neom-input-label-color);
}

.cds--list-box__label {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.16px;
}
