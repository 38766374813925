@use '../../globals';

.#{globals.$prefix}--chart-holder {
	.#{globals.$prefix}--#{globals.$charts-prefix}--toolbar {
		display: flex;

		.#{globals.$prefix}--overflow-menu-options {
			box-sizing: border-box;
			padding: 0;
			border: 0;
			margin: 0;
			font-family: inherit;
			font-size: 100%;
			vertical-align: baseline;
			box-shadow: 0 2px 6px var(--#{globals.$prefix}-shadow, rgba(0, 0, 0, 0.3));
			position: absolute;
			z-index: 6000;
			display: none;
			background-color: var(--#{globals.$prefix}-layer);
			width: 10rem;
			flex-direction: column;
			align-items: flex-start;
			top: 32px;
			inset-inline-start: 0;
			list-style: none;

			*,
			::before,
			::after {
				box-sizing: inherit;
			}

			.#{globals.$prefix}--overflow-menu-options__option {
				&:hover {
					background-color: var(--#{globals.$prefix}-layer-hover);
				}

				box-sizing: border-box;
				border: 0;
				margin: 0;
				font-family: inherit;
				font-size: 100%;
				vertical-align: baseline;
				display: flex;
				align-items: center;
				padding: 0;
				background-color: transparent;
				block-size: 2.5rem;
				inline-size: 100%;
				transition: background-color 0.11s cubic-bezier(0, 0, 0.38, 0.9);

				.#{globals.$prefix}--overflow-menu-options__btn {
					&:focus {
						outline: 2px solid var(--cds-focus, #0f62fe);
						outline-offset: -2px;
					}

					font-size: var(--#{globals.$prefix}-body-compact-01-font-size, 0.875rem);
					font-weight: var(--#{globals.$prefix}-body-compact-01-font-weight, 400);
					line-height: var(--#{globals.$prefix}-body-compact-01-line-height, 1.28572);
					letter-spacing: var(--#{globals.$prefix}-body-compact-01-letter-spacing, 0.16px);
					outline: 2px solid transparent;
					outline-offset: -2px;
					display: inline-flex;
					align-items: center;
					padding: 0 1rem;
					border: none;
					background-color: transparent;
					block-size: 100%;
					color: var(--#{globals.$prefix}-text-secondary, #525252);
					cursor: pointer;
					font-family: inherit;
					font-weight: 400;
					inline-size: 100%;
					max-inline-size: 11.25rem;
					text-align: start;
					transition:
						outline 0.11s cubic-bezier(0, 0, 0.38, 0.9),
						background-color 0.11s cubic-bezier(0, 0, 0.38, 0.9),
						color 0.11s cubic-bezier(0, 0, 0.38, 0.9);
				}

				*,
				::before,
				::after {
					box-sizing: inherit;
				}
			}
		}

		.#{globals.$prefix}--overflow-menu--flip {
			right: 0;
			left: unset;
			// left: -140px;

			&.is-open {
				display: table;
			}

			ul {
				margin: 0;
				padding: 0;
				list-style: none;
			}
		}

		.#{globals.$prefix}--overflow-menu,
		.#{globals.$prefix}--overflow-menu__trigger {
			width: 2rem;
			height: 2rem;

			&:hover {
				background-color: var(--#{globals.$prefix}-layer-hover);
			}

			&:focus {
				outline: 2px solid var(--cds-focus, #0f62fe);
				outline-offset: -2px;
			}

			*,
			::before,
			::after {
				box-sizing: inherit;
			}

			// align-items: center;
			appearance: none;
			background: none;
			block-size: 2.5rem;
			border: 0;
			box-sizing: border-box;
			cursor: pointer;
			display: flex;
			font-family: inherit;
			font-size: 100%;
			inline-size: 2.5rem;
			align-items: center;
			justify-content: center;
			margin: 0;
			min-height: 2.5rem;
			outline: 2px solid transparent;
			outline-offset: -2px;
			padding: 0;
			position: relative;
			text-align: start;
			transition:
				outline 110ms cubic-bezier(0, 0, 0.38, 0.9),
				background-color 110ms cubic-bezier(0, 0, 0.38, 0.9);
			vertical-align: baseline;

			> :first-child {
				margin-block-start: 0;
			}

			.#{globals.$prefix}--overflow-menu__icon {
				block-size: 1rem;
				fill: var(--cds-icon-primary, #161616);
				inline-size: 1rem;
			}
		}
	}
}
