//
// Copyright IBM Corp. 2023
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

@use '../theme';

/// Experimental - name and structure subject to change. Use at your own risk!
/// Adds AI gradient styles to a component
/// @access private
/// @param {String} $direction  - Direction of gradient from: `left`, `right`, `top`, and `bottom`
/// @param {Number} $span - Percentage span of gradient with regards to parent component
/// @example @include ai-gradient('right', '33%');
/// @group utilities
@mixin ai-gradient($direction: 'bottom', $span: 50%) {
  $deg: 0;
  @if $direction == 'bottom' {
    $deg: 0deg;
  } @else if $direction == 'left' {
    $deg: 90deg;
  } @else if $direction == 'top' {
    $deg: 180deg;
  } @else if $direction == 'right' {
    $deg: 270deg;
  }

  background-image: linear-gradient(
    $deg,
    theme.$ai-inner-shadow 0%,
    15%,
    theme.$ai-aura-end $span,
    transparent 100%
  );

  border-block-end-color: theme.$ai-border-strong;
}

/// Experimental - name and structure subject to change. Use at your own risk!
/// Adds callout gradient styles to a component
/// @access private
/// @example @include callout-gradient();
/// @param {String} $type  - Type of gradient, either 'default', 'selected' or 'hover'
/// @param {Number} $offset  - specify a pixel offset the callout should start from the bottom
/// @group utilities
@mixin callout-gradient($type: 'default', $offset: 0) {
  $start: 0%;
  @if $offset != 0 {
    $start: calc(0% + #{$offset});
  }

  @if $type == 'hover' {
    background: linear-gradient(
        0deg,
        theme.$slug-callout-aura-start-hover-01 $start,
        theme.$slug-callout-aura-end-hover-01 50%,
        transparent 50%
      ),
      linear-gradient(
        0deg,
        theme.$slug-callout-aura-start-hover-02 $start,
        theme.$slug-callout-aura-end-hover-02 50%,
        transparent 50%
      ),
      linear-gradient(
          180deg,
          theme.$slug-callout-gradient-top-hover $start,
          theme.$slug-callout-gradient-bottom-hover 100%
        )
        rgba(0, 0, 0, 0.01);
  } @else if $type == 'selected' {
    background: linear-gradient(
        0deg,
        theme.$slug-callout-aura-start-selected $start,
        theme.$slug-callout-aura-end-selected 50%,
        transparent 50%
      ),
      linear-gradient(
          180deg,
          theme.$slug-callout-gradient-top-selected $start,
          theme.$slug-callout-gradient-bottom-selected 100%
        )
        rgba(0, 0, 0, 0.01);
  } @else {
    background: linear-gradient(
          to top,
          theme.$ai-aura-start $start,
          theme.$ai-aura-end 50%
        )
        padding-box,
      linear-gradient(to top, theme.$background, theme.$background) padding-box,
      linear-gradient(to bottom, theme.$ai-border-start, theme.$ai-border-end)
        border-box,
      // Needed to underlay the transparent border
      linear-gradient(to top, theme.$background, theme.$background)
        border-box;
  }
}
