//
// Copyright IBM Corp. 2020
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

@use 'sass:color';
@use 'sass:map';
@use '../../config';
@use '../../colors';
@use '../../themes';
@use '../../theme' as *;
@use '../../utilities/component-tokens';
@use '@carbon/themes/scss/component-tokens' as tag;

// red
$tag-background-red: (
  fallback: map.get(tag.$tag-background-red, white-theme),
  values: (
    (
      theme: themes.$white,
      value: map.get(tag.$tag-background-red, white-theme),
    ),
    (
      theme: themes.$g10,
      value: map.get(tag.$tag-background-red, g-10),
    ),
    (
      theme: themes.$g90,
      value: map.get(tag.$tag-background-red, g-90),
    ),
    (
      theme: themes.$g100,
      value: map.get(tag.$tag-background-red, g-100),
    ),
  ),
) !default;

$tag-color-red: (
  fallback: map.get(tag.$tag-color-red, white-theme),
  values: (
    (
      theme: themes.$white,
      value: map.get(tag.$tag-color-red, white-theme),
    ),
    (
      theme: themes.$g10,
      value: map.get(tag.$tag-color-red, g-10),
    ),
    (
      theme: themes.$g90,
      value: map.get(tag.$tag-color-red, g-90),
    ),
    (
      theme: themes.$g100,
      value: map.get(tag.$tag-color-red, g-100),
    ),
  ),
) !default;

$tag-hover-red: (
  fallback: map.get(tag.$tag-hover-red, white-theme),
  values: (
    (
      theme: themes.$white,
      value: map.get(tag.$tag-hover-red, white-theme),
    ),
    (
      theme: themes.$g10,
      value: map.get(tag.$tag-hover-red, g-10),
    ),
    (
      theme: themes.$g90,
      value: map.get(tag.$tag-hover-red, g-90),
    ),
    (
      theme: themes.$g100,
      value: map.get(tag.$tag-hover-red, g-100),
    ),
  ),
) !default;

// magenta
$tag-background-magenta: (
  fallback: map.get(tag.$tag-background-magenta, white-theme),
  values: (
    (
      theme: themes.$white,
      value: map.get(tag.$tag-background-magenta, white-theme),
    ),
    (
      theme: themes.$g10,
      value: map.get(tag.$tag-background-magenta, g-10),
    ),
    (
      theme: themes.$g90,
      value: map.get(tag.$tag-background-magenta, g-90),
    ),
    (
      theme: themes.$g100,
      value: map.get(tag.$tag-background-magenta, g-100),
    ),
  ),
) !default;

$tag-color-magenta: (
  fallback: map.get(tag.$tag-color-magenta, white-theme),
  values: (
    (
      theme: themes.$white,
      value: map.get(tag.$tag-color-magenta, white-theme),
    ),
    (
      theme: themes.$g10,
      value: map.get(tag.$tag-color-magenta, g-10),
    ),
    (
      theme: themes.$g90,
      value: map.get(tag.$tag-color-magenta, g-90),
    ),
    (
      theme: themes.$g100,
      value: map.get(tag.$tag-color-magenta, g-100),
    ),
  ),
) !default;

$tag-hover-magenta: (
  fallback: map.get(tag.$tag-hover-magenta, white-theme),
  values: (
    (
      theme: themes.$white,
      value: map.get(tag.$tag-hover-magenta, white-theme),
    ),
    (
      theme: themes.$g10,
      value: map.get(tag.$tag-hover-magenta, g-10),
    ),
    (
      theme: themes.$g90,
      value: map.get(tag.$tag-hover-magenta, g-90),
    ),
    (
      theme: themes.$g100,
      value: map.get(tag.$tag-hover-magenta, g-100),
    ),
  ),
) !default;

// purple
$tag-background-purple: (
  fallback: map.get(tag.$tag-background-purple, white-theme),
  values: (
    (
      theme: themes.$white,
      value: map.get(tag.$tag-background-purple, white-theme),
    ),
    (
      theme: themes.$g10,
      value: map.get(tag.$tag-background-purple, g-10),
    ),
    (
      theme: themes.$g90,
      value: map.get(tag.$tag-background-purple, g-90),
    ),
    (
      theme: themes.$g100,
      value: map.get(tag.$tag-background-purple, g-100),
    ),
  ),
) !default;

$tag-color-purple: (
  fallback: map.get(tag.$tag-color-purple, white-theme),
  values: (
    (
      theme: themes.$white,
      value: map.get(tag.$tag-color-purple, white-theme),
    ),
    (
      theme: themes.$g10,
      value: map.get(tag.$tag-color-purple, g-10),
    ),
    (
      theme: themes.$g90,
      value: map.get(tag.$tag-color-purple, g-90),
    ),
    (
      theme: themes.$g100,
      value: map.get(tag.$tag-color-purple, g-100),
    ),
  ),
) !default;

$tag-hover-purple: (
  fallback: map.get(tag.$tag-hover-purple, white-theme),
  values: (
    (
      theme: themes.$white,
      value: map.get(tag.$tag-hover-purple, white-theme),
    ),
    (
      theme: themes.$g10,
      value: map.get(tag.$tag-hover-purple, g-10),
    ),
    (
      theme: themes.$g90,
      value: map.get(tag.$tag-hover-purple, g-90),
    ),
    (
      theme: themes.$g100,
      value: map.get(tag.$tag-hover-purple, g-100),
    ),
  ),
) !default;

// blue
$tag-background-blue: (
  fallback: map.get(tag.$tag-background-blue, white-theme),
  values: (
    (
      theme: themes.$white,
      value: map.get(tag.$tag-background-blue, white-theme),
    ),
    (
      theme: themes.$g10,
      value: map.get(tag.$tag-background-blue, g-10),
    ),
    (
      theme: themes.$g90,
      value: map.get(tag.$tag-background-blue, g-90),
    ),
    (
      theme: themes.$g100,
      value: map.get(tag.$tag-background-blue, g-100),
    ),
  ),
) !default;

$tag-color-blue: (
  fallback: map.get(tag.$tag-color-blue, white-theme),
  values: (
    (
      theme: themes.$white,
      value: map.get(tag.$tag-color-blue, white-theme),
    ),
    (
      theme: themes.$g10,
      value: map.get(tag.$tag-color-blue, g-10),
    ),
    (
      theme: themes.$g90,
      value: map.get(tag.$tag-color-blue, g-90),
    ),
    (
      theme: themes.$g100,
      value: map.get(tag.$tag-color-blue, g-100),
    ),
  ),
) !default;

$tag-hover-blue: (
  fallback: map.get(tag.$tag-hover-blue, white-theme),
  values: (
    (
      theme: themes.$white,
      value: map.get(tag.$tag-hover-blue, white-theme),
    ),
    (
      theme: themes.$g10,
      value: map.get(tag.$tag-hover-blue, g-10),
    ),
    (
      theme: themes.$g90,
      value: map.get(tag.$tag-hover-blue, g-90),
    ),
    (
      theme: themes.$g100,
      value: map.get(tag.$tag-hover-blue, g-100),
    ),
  ),
) !default;

// cyan
$tag-background-cyan: (
  fallback: map.get(tag.$tag-background-cyan, white-theme),
  values: (
    (
      theme: themes.$white,
      value: map.get(tag.$tag-background-cyan, white-theme),
    ),
    (
      theme: themes.$g10,
      value: map.get(tag.$tag-background-cyan, g-10),
    ),
    (
      theme: themes.$g90,
      value: map.get(tag.$tag-background-cyan, g-90),
    ),
    (
      theme: themes.$g100,
      value: map.get(tag.$tag-background-cyan, g-100),
    ),
  ),
) !default;

$tag-color-cyan: (
  fallback: map.get(tag.$tag-color-cyan, white-theme),
  values: (
    (
      theme: themes.$white,
      value: map.get(tag.$tag-color-cyan, white-theme),
    ),
    (
      theme: themes.$g10,
      value: map.get(tag.$tag-color-cyan, g-10),
    ),
    (
      theme: themes.$g90,
      value: map.get(tag.$tag-color-cyan, g-90),
    ),
    (
      theme: themes.$g100,
      value: map.get(tag.$tag-color-cyan, g-100),
    ),
  ),
) !default;

$tag-hover-cyan: (
  fallback: map.get(tag.$tag-hover-cyan, white-theme),
  values: (
    (
      theme: themes.$white,
      value: map.get(tag.$tag-hover-cyan, white-theme),
    ),
    (
      theme: themes.$g10,
      value: map.get(tag.$tag-hover-cyan, g-10),
    ),
    (
      theme: themes.$g90,
      value: map.get(tag.$tag-hover-cyan, g-90),
    ),
    (
      theme: themes.$g100,
      value: map.get(tag.$tag-hover-cyan, g-100),
    ),
  ),
) !default;

// teal
$tag-background-teal: (
  fallback: map.get(tag.$tag-background-teal, white-theme),
  values: (
    (
      theme: themes.$white,
      value: map.get(tag.$tag-background-teal, white-theme),
    ),
    (
      theme: themes.$g10,
      value: map.get(tag.$tag-background-teal, g-10),
    ),
    (
      theme: themes.$g90,
      value: map.get(tag.$tag-background-teal, g-90),
    ),
    (
      theme: themes.$g100,
      value: map.get(tag.$tag-background-teal, g-100),
    ),
  ),
) !default;

$tag-color-teal: (
  fallback: map.get(tag.$tag-color-teal, white-theme),
  values: (
    (
      theme: themes.$white,
      value: map.get(tag.$tag-color-teal, white-theme),
    ),
    (
      theme: themes.$g10,
      value: map.get(tag.$tag-color-teal, g-10),
    ),
    (
      theme: themes.$g90,
      value: map.get(tag.$tag-color-teal, g-90),
    ),
    (
      theme: themes.$g100,
      value: map.get(tag.$tag-color-teal, g-100),
    ),
  ),
) !default;

$tag-hover-teal: (
  fallback: map.get(tag.$tag-hover-teal, white-theme),
  values: (
    (
      theme: themes.$white,
      value: map.get(tag.$tag-hover-teal, white-theme),
    ),
    (
      theme: themes.$g10,
      value: map.get(tag.$tag-hover-teal, g-10),
    ),
    (
      theme: themes.$g90,
      value: map.get(tag.$tag-hover-teal, g-90),
    ),
    (
      theme: themes.$g100,
      value: map.get(tag.$tag-hover-teal, g-100),
    ),
  ),
) !default;

// green
$tag-background-green: (
  fallback: map.get(tag.$tag-background-green, white-theme),
  values: (
    (
      theme: themes.$white,
      value: map.get(tag.$tag-background-green, white-theme),
    ),
    (
      theme: themes.$g10,
      value: map.get(tag.$tag-background-green, g-10),
    ),
    (
      theme: themes.$g90,
      value: map.get(tag.$tag-background-green, g-90),
    ),
    (
      theme: themes.$g100,
      value: map.get(tag.$tag-background-green, g-100),
    ),
  ),
) !default;

$tag-color-green: (
  fallback: map.get(tag.$tag-color-green, white-theme),
  values: (
    (
      theme: themes.$white,
      value: map.get(tag.$tag-color-green, white-theme),
    ),
    (
      theme: themes.$g10,
      value: map.get(tag.$tag-color-green, g-10),
    ),
    (
      theme: themes.$g90,
      value: map.get(tag.$tag-color-green, g-90),
    ),
    (
      theme: themes.$g100,
      value: map.get(tag.$tag-color-green, g-100),
    ),
  ),
) !default;

$tag-hover-green: (
  fallback: map.get(tag.$tag-hover-green, white-theme),
  values: (
    (
      theme: themes.$white,
      value: map.get(tag.$tag-hover-green, white-theme),
    ),
    (
      theme: themes.$g10,
      value: map.get(tag.$tag-hover-green, g-10),
    ),
    (
      theme: themes.$g90,
      value: map.get(tag.$tag-hover-green, g-90),
    ),
    (
      theme: themes.$g100,
      value: map.get(tag.$tag-hover-green, g-100),
    ),
  ),
) !default;

// gray
$tag-background-gray: (
  fallback: map.get(tag.$tag-background-gray, white-theme),
  values: (
    (
      theme: themes.$white,
      value: map.get(tag.$tag-background-gray, white-theme),
    ),
    (
      theme: themes.$g10,
      value: map.get(tag.$tag-background-gray, g-10),
    ),
    (
      theme: themes.$g90,
      value: map.get(tag.$tag-background-gray, g-90),
    ),
    (
      theme: themes.$g100,
      value: map.get(tag.$tag-background-gray, g-100),
    ),
  ),
) !default;

$tag-color-gray: (
  fallback: map.get(tag.$tag-color-gray, white-theme),
  values: (
    (
      theme: themes.$white,
      value: map.get(tag.$tag-color-gray, white-theme),
    ),
    (
      theme: themes.$g10,
      value: map.get(tag.$tag-color-gray, g-10),
    ),
    (
      theme: themes.$g90,
      value: map.get(tag.$tag-color-gray, g-90),
    ),
    (
      theme: themes.$g100,
      value: map.get(tag.$tag-color-gray, g-100),
    ),
  ),
) !default;

$tag-hover-gray: (
  fallback: map.get(tag.$tag-hover-gray, white-theme),
  values: (
    (
      theme: themes.$white,
      value: map.get(tag.$tag-hover-gray, white-theme),
    ),
    (
      theme: themes.$g10,
      value: map.get(tag.$tag-hover-gray, g-10),
    ),
    (
      theme: themes.$g90,
      value: map.get(tag.$tag-hover-gray, g-90),
    ),
    (
      theme: themes.$g100,
      value: map.get(tag.$tag-hover-gray, g-100),
    ),
  ),
) !default;

// cool-gray
$tag-background-cool-gray: (
  fallback: map.get(tag.$tag-background-cool-gray, white-theme),
  values: (
    (
      theme: themes.$white,
      value: map.get(tag.$tag-background-cool-gray, white-theme),
    ),
    (
      theme: themes.$g10,
      value: map.get(tag.$tag-background-cool-gray, g-10),
    ),
    (
      theme: themes.$g90,
      value: map.get(tag.$tag-background-cool-gray, g-90),
    ),
    (
      theme: themes.$g100,
      value: map.get(tag.$tag-background-cool-gray, g-100),
    ),
  ),
) !default;

$tag-color-cool-gray: (
  fallback: map.get(tag.$tag-color-cool-gray, white-theme),
  values: (
    (
      theme: themes.$white,
      value: map.get(tag.$tag-color-cool-gray, white-theme),
    ),
    (
      theme: themes.$g10,
      value: map.get(tag.$tag-color-cool-gray, g-10),
    ),
    (
      theme: themes.$g90,
      value: map.get(tag.$tag-color-cool-gray, g-90),
    ),
    (
      theme: themes.$g100,
      value: map.get(tag.$tag-color-cool-gray, g-100),
    ),
  ),
) !default;

$tag-hover-cool-gray: (
  fallback: map.get(tag.$tag-hover-cool-gray, white-theme),
  values: (
    (
      theme: themes.$white,
      value: map.get(tag.$tag-hover-cool-gray, white-theme),
    ),
    (
      theme: themes.$g10,
      value: map.get(tag.$tag-hover-cool-gray, g-10),
    ),
    (
      theme: themes.$g90,
      value: map.get(tag.$tag-hover-cool-gray, g-90),
    ),
    (
      theme: themes.$g100,
      value: map.get(tag.$tag-hover-cool-gray, g-100),
    ),
  ),
) !default;

// warm-gray
$tag-background-warm-gray: (
  fallback: map.get(tag.$tag-background-warm-gray, white-theme),
  values: (
    (
      theme: themes.$white,
      value: map.get(tag.$tag-background-warm-gray, white-theme),
    ),
    (
      theme: themes.$g10,
      value: map.get(tag.$tag-background-warm-gray, g-10),
    ),
    (
      theme: themes.$g90,
      value: map.get(tag.$tag-background-warm-gray, g-90),
    ),
    (
      theme: themes.$g100,
      value: map.get(tag.$tag-background-warm-gray, g-100),
    ),
  ),
) !default;

$tag-color-warm-gray: (
  fallback: map.get(tag.$tag-color-warm-gray, white-theme),
  values: (
    (
      theme: themes.$white,
      value: map.get(tag.$tag-color-warm-gray, white-theme),
    ),
    (
      theme: themes.$g10,
      value: map.get(tag.$tag-color-warm-gray, g-10),
    ),
    (
      theme: themes.$g90,
      value: map.get(tag.$tag-color-warm-gray, g-90),
    ),
    (
      theme: themes.$g100,
      value: map.get(tag.$tag-color-warm-gray, g-100),
    ),
  ),
) !default;

$tag-hover-warm-gray: (
  fallback: map.get(tag.$tag-hover-warm-gray, white-theme),
  values: (
    (
      theme: themes.$white,
      value: map.get(tag.$tag-hover-warm-gray, white-theme),
    ),
    (
      theme: themes.$g10,
      value: map.get(tag.$tag-hover-warm-gray, g-10),
    ),
    (
      theme: themes.$g90,
      value: map.get(tag.$tag-hover-warm-gray, g-90),
    ),
    (
      theme: themes.$g100,
      value: map.get(tag.$tag-hover-warm-gray, g-100),
    ),
  ),
) !default;

$tag-tokens: (
  tag-background-red: $tag-background-red,
  tag-color-red: $tag-color-red,
  tag-hover-red: $tag-hover-red,
  tag-background-magenta: $tag-background-magenta,
  tag-color-magenta: $tag-color-magenta,
  tag-hover-magenta: $tag-hover-magenta,
  tag-background-purple: $tag-background-purple,
  tag-color-purple: $tag-color-purple,
  tag-hover-purple: $tag-hover-purple,
  tag-background-blue: $tag-background-blue,
  tag-color-blue: $tag-color-blue,
  tag-hover-blue: $tag-hover-blue,
  tag-background-cyan: $tag-background-cyan,
  tag-color-cyan: $tag-color-cyan,
  tag-hover-cyan: $tag-hover-cyan,
  tag-background-teal: $tag-background-teal,
  tag-color-teal: $tag-color-teal,
  tag-hover-teal: $tag-hover-teal,
  tag-background-green: $tag-background-green,
  tag-color-green: $tag-color-green,
  tag-hover-green: $tag-hover-green,
  tag-background-gray: $tag-background-gray,
  tag-color-gray: $tag-color-gray,
  tag-hover-gray: $tag-hover-gray,
  tag-background-cool-gray: $tag-background-cool-gray,
  tag-color-cool-gray: $tag-color-cool-gray,
  tag-hover-cool-gray: $tag-hover-cool-gray,
  tag-background-warm-gray: $tag-background-warm-gray,
  tag-color-warm-gray: $tag-color-warm-gray,
  tag-hover-warm-gray: $tag-hover-warm-gray,
);

$tag-background-red: component-tokens.get-var(
  $tag-background-red,
  'tag-background-red'
);

$tag-color-red: component-tokens.get-var($tag-color-red, 'tag-color-red');

$tag-hover-red: component-tokens.get-var($tag-hover-red, 'tag-hover-red');

$tag-background-magenta: component-tokens.get-var(
  $tag-background-magenta,
  'tag-background-magenta'
);

$tag-color-magenta: component-tokens.get-var(
  $tag-color-magenta,
  'tag-color-magenta'
);

$tag-hover-magenta: component-tokens.get-var(
  $tag-hover-magenta,
  'tag-hover-magenta'
);

$tag-background-purple: component-tokens.get-var(
  $tag-background-purple,
  'tag-background-purple'
);

$tag-color-purple: component-tokens.get-var(
  $tag-color-purple,
  'tag-color-purple'
);

$tag-hover-purple: component-tokens.get-var(
  $tag-hover-purple,
  'tag-hover-purple'
);

$tag-background-blue: component-tokens.get-var(
  $tag-background-blue,
  'tag-background-blue'
);

$tag-color-blue: component-tokens.get-var($tag-color-blue, 'tag-color-blue');

$tag-hover-blue: component-tokens.get-var($tag-hover-blue, 'tag-hover-blue');

$tag-background-cyan: component-tokens.get-var(
  $tag-background-cyan,
  'tag-background-cyan'
);

$tag-color-cyan: component-tokens.get-var($tag-color-cyan, 'tag-color-cyan');

$tag-hover-cyan: component-tokens.get-var($tag-hover-cyan, 'tag-hover-cyan');

$tag-background-teal: component-tokens.get-var(
  $tag-background-teal,
  'tag-background-teal'
);

$tag-color-teal: component-tokens.get-var($tag-color-teal, 'tag-color-teal');

$tag-hover-teal: component-tokens.get-var($tag-hover-teal, 'tag-hover-teal');

$tag-background-green: component-tokens.get-var(
  $tag-background-green,
  'tag-background-green'
);

$tag-color-green: component-tokens.get-var($tag-color-green, 'tag-color-green');

$tag-hover-green: component-tokens.get-var($tag-hover-green, 'tag-hover-green');

$tag-background-gray: component-tokens.get-var(
  $tag-background-gray,
  'tag-background-gray'
);

$tag-color-gray: component-tokens.get-var($tag-color-gray, 'tag-color-gray');

$tag-hover-gray: component-tokens.get-var($tag-hover-gray, 'tag-hover-gray');

$tag-background-cool-gray: component-tokens.get-var(
  $tag-background-cool-gray,
  'tag-background-cool-gray'
);

$tag-color-cool-gray: component-tokens.get-var(
  $tag-color-cool-gray,
  'tag-color-cool-gray'
);

$tag-hover-cool-gray: component-tokens.get-var(
  $tag-hover-cool-gray,
  'tag-hover-cool-gray'
);

$tag-background-warm-gray: component-tokens.get-var(
  $tag-background-warm-gray,
  'tag-background-warm-gray'
);

$tag-color-warm-gray: component-tokens.get-var(
  $tag-color-warm-gray,
  'tag-color-warm-gray'
);

$tag-hover-warm-gray: component-tokens.get-var(
  $tag-hover-warm-gray,
  'tag-hover-warm-gray'
);
