@use '@carbon/layout';
@use '@carbon/colors';

$modal-yellow: #e9c150;

.neom-modal-main-container {
  width: 100%;
  height: 100%;
  background-image: url('/assets/images/login-bg.jpg');
  display: flex;
  align-items: center;
  justify-items: center;
  background-size: cover;

  .neom-modal-container {
    padding: layout.$spacing-07 layout.$spacing-07 layout.$spacing-10 layout.$spacing-07;
    text-align: center;
    background-color: rgba(38, 38, 38, 0.9);

    .cds--label {
      color: #ffff;
    }

    .neom-modal-logo {
      width: 111px;
      height: 111px;
    }

    .neom-modal-title {
      color: $modal-yellow;
      font-size: 24px;
      font-weight: bold;
    }

    .neom-modal-subtitle {
      font-size: 14px;
    }

    .neom-modal-container-row {
      margin-top: layout.$spacing-07;
      width: 450px;
    }

    .neom-continue-btn {
      background-color: $modal-yellow;
      color: colors.$black;
      min-width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 0;
      padding-right: 0;
    }

    .neom-modal-azure-btn {
      background-color: colors.$white;
      color: colors.$black;
      min-width: 100%;
      align-items: center;
      justify-content: flex-start;
      gap: layout.$spacing-03;
      padding-left: layout.$spacing-06;
      font-size: layout.$spacing-05;

      .btn-logo {
        width: layout.$spacing-06;
        height: layout.$spacing-06;
      }
    }

    .neom-modal-or-separator {
      display: flex;
      flex-direction: row;

      &:after,
      &:before {
        content: '';
        flex: 1 1;
        border-bottom: 1px solid;
        margin: auto;
      }

      &:before {
        margin-right: 10px;
      }

      &:after {
        margin-left: 10px;
      }
    }
  }
}
