.neom-page-title {
  font-size: 22px;
  font-weight: 600;
  color: var(--neom-page-title-color);
}

.neom-form-container {
  display: flex;
  justify-content: space-between;

  .neom-dynamic-form {
    display: flex;
    flex-direction: column;
  }
}
