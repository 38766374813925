/* Put here styles that need to be overriden */
.cds--cc--legend {
  min-width: 100px;
  margin-left: 16px;
}

.cds--content {
  padding: 15px;
}

.cds--white {
  --cds-focus: var(--neom-focus-color);
  --cds-border-interactive: var(--neom-focus-color);
}

.cds--loading__stroke {
  stroke: #ebc03f;
}

.cds--list-box__menu-item--active {
  outline: 2px solid var(--neom-focus-color);
}

.cds--list-box__menu:focus {
  outline: 2px solid var(--neom-focus-color);
}

.cds--list-box__menu-item__option:focus {
  outline: 2px solid var(--neom-focus-color);
}

.cds--list-box__menu-item--highlighted {
  outline: 2px solid var(--neom-focus-color);
}

.cds--loading-overlay {
  z-index: 10000;
}

.cds--file {
  .cds--btn--primary {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    padding: 20px;
    gap: 4px;
    background-color: var(--neom-button-color);
    border-radius: 12px;
    height: 12px;
    color: var(--neom-button-text-color);

    &:hover {
      background-color: var(--neom-button-hover-color);
      cursor: pointer;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px var(--neom-button-hover-color);
      background-color: var(--neom-button-color);
      border-color: transparent;
    }
  }
}

.cds--modal-close {
  &:focus {
    border-color: transparent;
  }
}

.cds--btn--primary:focus {
  box-shadow: none;
}

.cds--header {
  background-color: var(--neom--sidenav--background);
}

.cds-table-container {
  width: 100%;
  height: 100%;
}

.cds--tab-content:focus,
.cds--tabs .cds--tabs__nav-item:focus{
  outline: unset;
}

.cds--tab-content{
  padding: 10px;
}

.cds--tabs .cds--tabs__nav-item{
  font-size: 12px;
  height: 40px;
}

.cds--tabs{
  max-block-size: var(--cds-layout-size-height-md);
  min-block-size: var(--cds-layout-size-height-md);
}
