@use '@carbon/styles/scss/motion';
@use '@carbon/styles/scss/theme';
@use '../globals';
@use '../tokens';

.#{globals.$prefix}--#{globals.$charts-prefix}--tooltip {
	background-color: theme.$layer-02;
	pointer-events: none;
	@include motion.motion(standard, expressive);
	display: inline;
	visibility: visible;
	box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
	position: absolute;
	word-wrap: break-word;
	z-index: 1059;
	font-family: var(--#{globals.$prefix}-charts-font-family-condensed);
	transition:
		visibility 0s linear 0.1s,
		opacity 0.1s;

	&.hidden {
		opacity: 0;
		visibility: hidden;
		transition:
			visibility 0s linear 0s,
			opacity 0.1s;
		@include motion.motion(standard, expressive);
	}

	.content-box {
		color: theme.$text-primary;

		.title-tooltip {
			width: auto;
			padding: 4px;
			min-width: 20px;
			max-width: 270px;

			p {
				margin: 2px;
				font-size: 12px;
				line-height: 1rem;
			}
		}

		.datapoint-tooltip {
			display: flex;
			padding: 4px;
			flex-flow: row nowrap;
			width: auto;
			min-width: 20px;
			justify-content: flex-start;
			align-items: center;

			div.label {
				display: flex;
				flex: 1;

				p {
					flex: 1;
					padding-right: 8px;
				}

				span.label-icon svg {
					height: 12px;
					padding-top: 3px;
					vertical-align: top;
					padding-left: 4px;
					width: auto;
					fill: tokens.$layer-inverse-absolute;
				}
			}

			&.bold {
				// TODO-V11
				// font-weight: font-weight('semibold');

				div.label p {
					font-weight: 600;
				}
			}

			p {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				line-height: 16px;
				font-size: 12px;
				display: inline-block;
				margin: 0;
				padding: 0;
				border: 0;

				&.value {
					width: auto;
					margin-left: 6px;
				}
			}
		}

		ul.multi-tooltip {
			margin: 0;
			padding: 0;

			li {
				list-style: none;
				position: relative;

				&:not(:last-child) {
					border-bottom: 1px solid tokens.$tooltip-line-border;
				}
			}
		}

		svg.arrow-right {
			rect {
				fill: none;
			}
		}
	}

	.tooltip-color {
		position: absolute;
		left: 0;
		top: 0;
		width: 4px;
		height: 100%;

		@media (forced-colors: active) {
			forced-color-adjust: none;
		}

		& + div.label p {
			margin-left: 4px;
		}
	}
}
